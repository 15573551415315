import { createVuetify } from "vuetify";
// import { VBtn, VRow, VCol, VMenu, VList, VListItem, VListItemTitle } from "vuetify/components";
// import * as components from "vuetify/components";

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    theme: 'light',
    // components: components,
    ssr: true,
  });

  nuxtApp.vueApp.use(vuetify);
});