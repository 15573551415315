<script setup lang="ts">
type SizeMode = 'xs'| 'sm'| 'md'| 'lg'| 'xl'| 'xxl' ;

let defaultValue = 'xxl'
let isMobile = false
if(process.server){
  const headers = useRequestHeaders(['user-agent'])
  const userAgent = headers["user-agent"]
  isMobile = /(Android|webOS|iPhone|iPod|iPad|tablet|BlackBerry|Mobile)/i.test(userAgent)
  console.error(userAgent, isMobile)
  if(isMobile){
    defaultValue = 'xs'
  }
}

const sizeMode = ref<SizeMode>(defaultValue);

const BreakPoints = [576, 768, 992, 1200, 1400];
const BreakPointsMode: SizeMode[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

function getSizeModeByWidth(width: number): SizeMode {
  if(width < 992 || window.lib.flexible.versions().mobile){
    return 'xs'
  }
  // for(var i = 0; i < BreakPoints.length; i++) {
  //   if (width < BreakPoints[i]) {
  //     return BreakPointsMode[i];
  //   }
  // }
  return 'xxl';
}

function applySizeModeBySize() {
  const name = getSizeModeByWidth(window.innerWidth)
  document.body.setAttribute('class', `body-size-${name}`)
  sizeMode.value = name;
}
const stlyeStr = ref('display: none;')
onMounted(async () => {
  if(process.client){
    // await nextTick()
    applySizeModeBySize();
    window.addEventListener('resize', applySizeModeBySize);
    stlyeStr.value = ''
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', applySizeModeBySize);
});

const sizeModeClass = computed(() => {
  return `root-size-${sizeMode.value}`;
});

provide('sizeMode', sizeMode);

</script>
<template>
  <div :class="sizeModeClass" :style="stlyeStr">
    <slot></slot>
  </div>
</template>