// import * as ElementPlus from 'element-plus/dist/index.full'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import zhCn from 'element-plus/es/locale/lang/zh-cn'
// export default defineNuxtPlugin(async (nuxtApp) => {
//     nuxtApp.vueApp.use(ElementPlus)
//     for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//         nuxtApp.vueApp.component(key, component)
//     }
// })
export default defineNuxtPlugin((nuxtApp) => {
  
})
// import ElementPlus from 'element-plus'
// import { ID_INJECTION_KEY } from 'element-plus';
// export default defineNuxtPlugin(nuxtApp => {
//     nuxtApp.vueApp.use(ElementPlus)
//     nuxtApp.vueApp.provide(ID_INJECTION_KEY, {
//         prefix: Math.floor(Math.random() * 10000),
//         current: 0,
//     })
// })

// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// export default defineNuxtPlugin(async (NuxtApp) => {
//   // nuxtApp包含的属性可看文档 https://nuxt.com/docs/guide/going-further/internals

//   // 全局组件引入
//   for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//     NuxtApp.vueApp.component(key, component)
//   }
// })