export default defineNuxtRouteMiddleware((to, from) => {
  // if (process.server){ // 在服务器端处理路由
  //   const nuxtApp = useNuxtApp()
  // } else { // 在客户端处理路由
  //   // 是否是移动端设备
  //   const isMobile = /(Android|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(navigator.userAgent)
  //   // 是否是手机端路由
  //   const isRouterMobile = /mobile/i.test(to.fullPath)
  //   if ( !isMobile && isRouterMobile){ // 手机端路由跳转pc端
  //     let path = to.fullPath.replace('/mobile','')
  //     return navigateTo(path)
  //   } else if(isMobile && !isRouterMobile){ // pc端路由跳转手机端
  //     let path = '/mobile' + to.fullPath
  //     return navigateTo(path)
  //   }
  // }
})

// export default defineNuxtRouteMiddleware((to) => {
//   let isMobile = (ua: any) => {
//     // return true
//     // console.log(typeof ua, ua)
//     return !!String(ua).match(/AppleWebKit.*Mobile.*/)
//   }
  
//   const headers = useRequestHeaders(['user-agent'])
//   const userAgent = headers["user-agent"]
//   console.log(isMobile(userAgent), 'isMobile(userAgent)')

//   if((to.fullPath.indexOf('/m') > -1 || to.fullPath.indexOf('/m/') > -1) && !isMobile(userAgent)){
//     let url = to.fullPath.substring(2)
    
//     return navigateTo(url)
//   }
//   if((to.fullPath.indexOf('/m') === -1 || to.fullPath.indexOf('/m/') === -1) && isMobile(userAgent)){
//     return navigateTo(`/m${to.fullPath}`)
//   }
//   console.log(to.fullPath)
//   // if(!isMobile(userAgent) && /^\/m\/*?/.test(to.fullPath)){
//   //   return to.fullPath.substring(2)
//   // }
//   // if(isMobile(userAgent) && !/^\/m\/*?/.test(to.fullPath)){
//   //   return `/m${to.fullPath}`
//   // }
//   // const { $config } = useNuxtApp()
// })
