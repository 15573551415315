<script lang='ts' setup>
  const props = defineProps({
    error: Object
  })
  const handleError = () => {
    clearError({ redirect: '/404' })
  }
  onMounted(() => {
    console.error(props)
    if(props.error?.statusCode === 404){
      return handleError()
    }
  })
</script>
<template>
  <div style="padding-top: 100px; text-align: center; width: 100%;">
    <template v-if="props.error?.statusCode !== 404">
      <h4>Error!</h4>
      <span >
        {{ props?.error?.message || 'Encouter Error!' }}
      </span>
    </template>
  </div>
</template>
<style lang="scss" scoped>
</style>
