import revive_payload_client_4sVQNw7RlN from "E:/code/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "E:/code/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "E:/code/website/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import plugin_vue3_A0OWXRrUgq from "E:/code/website/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "E:/code/website/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "E:/code/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "E:/code/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eskviOYyLt from "E:/code/website/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "E:/code/website/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "E:/code/website/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "E:/code/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import element_plus_client_Rokf4bD7gg from "E:/code/website/plugins/element-plus.client.ts";
import vuetify_7h9QAQEssH from "E:/code/website/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_eskviOYyLt,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_UciE0i6zes,
  element_plus_client_Rokf4bD7gg,
  vuetify_7h9QAQEssH
]